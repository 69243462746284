import { REWARDFUL_ID } from "./config";

// Referral campaign
export function initRewardful() {
  if (REWARDFUL_ID) {
    const script1 = document.createElement("script");
    script1.innerHTML = `(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`;

    const script2 = document.createElement("script");
    script2.async = true;
    script2.src = "https://r.wdfl.co/rw.js";
    script2.setAttribute("data-rewardful", REWARDFUL_ID);

    document.head.appendChild(script1);
    document.head.appendChild(script2);
  } else {
    console.log("Failed to initialize Rewardful!");
  }
}
